<template>
    <v-container>
      <!-- Уведомление -->
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.message }}
      </v-snackbar>
  
      <!-- Панели раскрытия -->
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(group, type) in groupedTemplates"
          :key="type"
        >
          <v-expansion-panel-title>
            <b>{{ type }}</b> ({{ group.length }} шаблонов)
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">
                    <div style="display: flex; align-items: center;">
                      ID
                      <v-text-field
                        v-model="searchQueries[type].id"
                        placeholder="Search"
                        dense
                        clearable
                        outlined
                        hide-details
                        style="margin-left: 10px; width: 150px;"
                      ></v-text-field>
                    </div>
                  </th>
                  <th class="text-left">
                    <div style="display: flex; align-items: center;">
                      Name
                      <v-text-field
                        v-model="searchQueries[type].name"
                        placeholder="Search"
                        dense
                        clearable
                        outlined
                        hide-details
                        style="margin-left: 10px; width: 150px;"
                      ></v-text-field>
                    </div>
                  </th>
                  <th class="text-left">
                    <div style="display: flex; align-items: center;">
                      Stage
                      <v-text-field
                        v-model="searchQueries[type].stage"
                        placeholder="Search"
                        dense
                        clearable
                        outlined
                        hide-details
                        style="margin-left: 10px; width: 150px;"
                      ></v-text-field>
                    </div>
                  </th>
                  <th class="text-left">Content</th>
                  <th class="text-left">Delete</th>
                  <th class="text-left">Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="template in paginatedGroup[type]"
                  :key="template.id"
                >
                  <td>{{ template.id }}</td>
                  <td>{{ template.name }}</td>
                  <td>{{ template.stage }}</td>
                  <td>
                    <v-btn
                      small
                      color="primary"
                      @click.stop="openPopup(template)"
                    >
                      Show
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon color="red" @click="confirmDelete(template)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon color="yellow" @click="confirmEdit(template)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-table>
  
            <!-- Пагинация -->
            <v-pagination
              v-if="filteredGroup[type]?.length > itemsPerPage"
              v-model="currentPage[type]"
              :length="Math.ceil(filteredGroup[type]?.length / itemsPerPage)"
              color="primary"
              class="mt-4"
            ></v-pagination>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-dialog v-model="editPopup" max-width="600px">
        <v-card>
            <v-card-title>Edit Template</v-card-title>
            <v-card-text>
            <v-form ref="editForm" v-model="valid">
                <v-text-field
                v-model="editedTemplate.name"
                label="Name"
                :rules="[rules.required]"
                dense
                outlined
                ></v-text-field>
                <v-text-field
                v-model="editedTemplate.stage"
                label="Stage"
                :rules="[rules.required]"
                dense
                outlined
                ></v-text-field>
                <v-textarea
                v-model="editedTemplate.content"
                label="Content"
                :rules="[rules.required]"
                dense
                outlined
                ></v-textarea>
                <v-text-field
                v-model="editedTemplate.type"
                label="Type"
                :rules="[rules.required]"
                dense
                outlined
                ></v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-btn color="blue darken-1" text @click="saveTemplate">Save</v-btn>
            <v-btn color="grey darken-1" text @click="closeEditPopup">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  
      <!-- Popup для подтверждения удаления -->
      <v-dialog v-model="deletePopup" max-width="400px">
        <v-card>
          <v-card-title>Confirm Deletion</v-card-title>
          <v-card-text>
            Are you sure you want to delete "{{ selectedTemplate?.name }}"?
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="deleteTemplate">Yes</v-btn>
            <v-btn @click="closeDeletePopup">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Popup для отображения контента -->
      <v-dialog
        v-model="showPopup"
        fullscreen
        persistent
        @keydown.esc="closePopup"
      >
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <div class="element">
                <div class="name">
                  <b>Template: </b> &nbsp;
                </div>
                <div class="content">
                  {{ selectedTemplate ? selectedTemplate.name : '' }}
                </div>
              </div>
  
              <div class="element">
                <div class="name">
                  <b>Stage: </b> &nbsp;
                </div>
                <div class="content">
                  {{ selectedTemplate ? selectedTemplate.stage : '' }}
                </div>
              </div>
              <div class="element">
                <div class="name">
                  <b>ID: </b> &nbsp;
                </div>
                <div class="content">
                  {{ selectedTemplate ? selectedTemplate.id : '' }}
                </div>
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closePopup">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div v-if="selectedTemplate">
              <p v-html="selectedTemplate.content"></p>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import api from "@/api";
  
  export default {
    data() {
      return {
        templates: [],
        groupedTemplates: {},
        filteredGroup: {},
        paginatedGroup: {},
        currentPage: {},
        itemsPerPage: 10,
        searchQueries: {},
        selectedTemplate: null,
        showPopup: false,
        deletePopup: false,
        snackbar: {
          show: false,
          message: "",
          color: "green",
          timeout: 3000,
        },
        editPopup: false, // Управление видимостью окна редактирования
        editedTemplate: {}, // Шаблон для редактирования
        rules: {
            required: (value) => !!value || "This field is required",
        },
      };
    },
    async created() {
      await this.fetchTemplates();
    },
    methods: {
      async fetchTemplates() {
        try {
          const response = await api.get("/");
          this.templates = response.data;
          this.groupTemplatesByType();
        } catch (error) {
          console.error("Error fetching templates:", error);
        }
      },
      groupTemplatesByType() {
        this.groupedTemplates = this.templates.reduce((groups, template) => {
          if (!groups[template.type]) {
            groups[template.type] = [];
            this.currentPage[template.type] = 1;
            this.searchQueries[template.type] = { id: "", name: "", stage: "" };
          }
          groups[template.type].push(template);
          return groups;
        }, {});
        this.updateFilteredGroup();
      },
      updateFilteredGroup() {
        this.filteredGroup = Object.fromEntries(
          Object.entries(this.groupedTemplates).map(([type, group]) => {
            const queries = this.searchQueries[type] || {};
            const filtered = group.filter((template) => {
              const matchId = queries.id ? template.id.toString().includes(queries.id) : true;
              const matchName = queries.name
                ? template.name.toLowerCase().includes(queries.name.toLowerCase())
                : true;
              const matchStage = queries.stage
                ? template.stage.toLowerCase().includes(queries.stage.toLowerCase())
                : true;
  
              return matchId && matchName && matchStage;
            });
            return [type, filtered];
          })
        );
        this.updatePaginatedGroup();
      },
      updatePaginatedGroup() {
        this.paginatedGroup = Object.fromEntries(
          Object.entries(this.filteredGroup).map(([type, group]) => {
            const currentPage = this.currentPage[type] || 1;
            const start = (currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return [type, group.slice(start, end)];
          })
        );
      },
      openPopup(template) {
        this.selectedTemplate = template;
        this.showPopup = true;
      },
      closePopup() {
        this.showPopup = false;
        this.selectedTemplate = null;
      },
      confirmDelete(template) {
        this.selectedTemplate = template;
        this.deletePopup = true;
      },
      async deleteTemplate() {
        try {
          await api.delete(`/${this.selectedTemplate.id}`);
          this.snackbar = {
            show: true,
            message: `Template "${this.selectedTemplate.id}" deleted successfully.`,
            color: "green",
          };
          await this.fetchTemplates();
        } catch (error) {
          this.snackbar = {
            show: true,
            message: error.response?.data?.detail || "Failed to delete template.",
            color: "red",
          };
        } finally {
          this.deletePopup = false;
          this.selectedTemplate = null;
        }
      },
      closeDeletePopup() {
        this.deletePopup = false;
        this.selectedTemplate = null;
      },
      confirmEdit(template) {
    this.editedTemplate = { ...template }; // Создаем копию редактируемого шаблона
    this.editPopup = true;
  },
  // Сохранение изменений
  async saveTemplate() {
    if (!this.$refs.editForm.validate()) {
      this.showSnackbar("Please fill all required fields.", "red");
      return;
    }

    try {
      await api.put(`/${this.editedTemplate.id}`, this.editedTemplate);
      this.showSnackbar(`Template "${this.editedTemplate.name}" updated successfully.`, "green");
      this.editPopup = false; // Закрываем окно
      await this.fetchTemplates(); // Обновляем список шаблонов
    } catch (error) {
      console.error("Error updating template:", error);
      this.showSnackbar(
        error.response?.data?.detail || "Failed to update template.",
        "red"
      );
    }
  },
  // Закрытие окна редактирования
  closeEditPopup() {
    this.editPopup = false;
    this.editedTemplate = {};
  },
  showSnackbar(message, color) {
            this.snackbar.message = message;
            this.snackbar.color = color;
            this.snackbar.show = true;

            // Автоматически скрыть уведомление через `timeout`
            setTimeout(() => {
                this.snackbar.show = false;
            }, this.snackbar.timeout);
        }, 
    },
    watch: {
      searchQueries: {
        handler() {
          this.updateFilteredGroup();
        },
        deep: true,
      },
      currentPage: {
        handler() {
          this.updatePaginatedGroup();
        },
        deep: true,
      },
    },
  };
  </script>
  
  <style>
  .element {
    display: flex;
    flex-direction: row;
  }
  
  .v-toolbar__content {
    height: 100px !important;
  }
  </style>