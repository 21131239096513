<template>
  <v-card>
    <v-card-title>Create template</v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field v-model="template.name" label="Name"></v-text-field>
        <v-text-field v-model="template.stage" label="Stage"></v-text-field>
        <v-text-field v-model="template.type" label="Type"></v-text-field>
        <v-textarea v-model="template.content" label="Content"></v-textarea>
        <v-btn color="primary" @click="submit">Create</v-btn>
      </v-form>
    </v-card-text>
  </v-card>

  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    top
    right
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      template: {
        name: "",
        content: "",
        stage: "",
        type: "",
      },
      snackbar: {
        show: false,
        message: "",
        color: "green",
        timeout: 3000,
      },
    };
  },
  methods: {
    async created() {
      await this.fetchTemplates();
    }, 
    async fetchTemplates() {
      setTimeout(() => {
        window.location.reload(); // Обновляем страницу
      }, 1000); // Задержка 2 секунды
    },
    async submit() {
      try {
        // Отправка данных шаблона на сервер
        const response = await api.post("/", this.template);
        console.log("Template submitted successfully:", response.data);

        // Сброс формы после успешной отправки
        this.template = {
          name: "",
          content: "",
          stage: "",
          type: "",
        };

        // Показ уведомления об успешной отправке
        this.showSnackbar("Template submitted successfully.", "green");

        this.fetchTemplates()


      } catch (error) {
        console.error("Error submitting template:", error);
        this.showSnackbar("Failed to submit template.", "red");
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
};
</script>