import { createRouter, createWebHistory } from 'vue-router';
import TemplateView from '@/views/TemplateView.vue';

const routes = [
  { path: '/', name: 'templates', component: TemplateView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;