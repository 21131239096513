<template>
  <v-container>
    <template-list
      :onTemplateSelected="editTemplate"
    ></template-list>
    <template-form
      :template="selectedTemplate"
      @formSubmitted="handleFormSubmitted"
    ></template-form>
  </v-container>
</template>

<script>
import TemplateList from "@/components/TemplateList.vue";
import TemplateForm from "@/components/TemplateForm.vue";

export default {
  components: {
    TemplateForm, 
    TemplateList,
  },
  data() {
    return {
      selectedTemplate: {}, // Инициализируем как пустой объект
    };
  },
  methods: {
    editTemplate(template) {
      this.selectedTemplate = { ...template };
    },
    handleFormSubmitted() {
      console.log("Form submitted"); // Для отладки
    },
  },
};
</script>